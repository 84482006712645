/**
 * Entrypoint for webpack when developing. Lazily loads the main development module.
 */
const moduleLoading = import("./main.development");

const init = (...args) => {
  moduleLoading.then(module => {
    module.StagePlayer(...args);
  });
};

init({ target: "#root" });
